<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">

            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('shipping-rule-modal', {id: 0},shown,{width: '800px'})"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.shipping_rule.label.id') }}</b-th>
                            <b-th>{{ $t('settings.shipping_rule.label.method') }}</b-th>
                            <b-th>{{ $t('settings.shipping_rule.label.premium') }}</b-th>
                            <b-th>{{ $t('settings.shipping_rule.label.order') }}</b-th>
                            <b-th>{{ $t('settings.shipping_rule.label.conditions') }}</b-th>
                            <b-th>{{ $t('settings.shipping_rule.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="rule in rules" :key="rule.id">
                            <b-td>{{ rule.id }}</b-td>
                            <b-td>{{ rule.shipping_method }}</b-td>
                            <b-td>
                                <CBadge :color="rule.premium ? 'success' : 'danger' ">
                                    {{ rule.premium ? $t('common.button.yes') : $t('common.button.no') }}
                                </CBadge>
                            </b-td>
                            <b-td>{{ rule.order }}</b-td>
                            <b-td>{{ rule.conditions }}</b-td>
                            <b-td>
                                <b-button
                                    @click="$root.$children[0].openModal('shipping-rule-modal', {id: rule.id},shown,{width: '800px'})"
                                    size="sm"
                                    variant="primary"
                                    class="mr-2"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>

                                <b-button @click="deleteRule(rule.id)"
                                          size="sm"
                                          variant="danger"
                                          :title="$t('common.title.delete')"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import Draggable from "vuedraggable"

export default {
    name: 'SettingsShippingRules',
    components: {
        Draggable
    },
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            rules: [],
        }
    },
    methods: {
        ...mapGetters('ShippingRule', ['getRules']),
        shown() {
            const rulesFetch = this.$store.dispatch('ShippingRule/fetchRules')

            Promise.all([rulesFetch])
                .then(() => {
                    this.rules = this.getRules()
                })
                .finally(() => {
                    this.loading = false
                })
            this.$root.afterSettingsUpdate()
        },
        deleteRule(id) {
            this.$removeConfirm('ShippingRule/deleteRule', id, this.shown)
        }
    },
    mounted() {
        this.shown()
    }
}
</script>